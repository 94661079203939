<template>
  <div id="dialogSettingPermission">
    <v-dialog v-model="dialog" max-width="500" persistent>
      <v-card>
        <v-card-title>
          <span class="headline">{{ dialogTitle }}</span
          ><v-divider class="mx-4" inset vertical></v-divider>
        </v-card-title>
        <v-card-text>
          <v-container>
            <v-row>
              <v-col cols="12" v-if="isEdit">
                <v-text-field
                  v-model="item.PermissionId"
                  label="รหัส"
                  dense
                  required
                  readonly
                  ref="PermissionId"
                  @keydown.enter="NextFocus('PermissionName')"
                ></v-text-field>
              </v-col>
              <v-col cols="12">
                <v-text-field
                  v-model="item.PermissionName"
                  label="ชื่อ"
                  dense
                  required
                  ref="PermissionName"
                  @keydown.enter="NextFocus('Status')"
                ></v-text-field>
              </v-col>
              <v-col cols="12" v-if="isEdit">                
                <v-autocomplete
                  v-model="item.Status"
                  :items="enumStatus"
                  item-value="id"
                  item-text="name"
                  :return-object="false"
                  dense
                  label="สถานะ"
                  ref="Status"
                ></v-autocomplete>
              </v-col>
            </v-row>
          </v-container>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn class="primary--text" @click="cancel">
            ยกเลิก
          </v-btn>
          <v-btn class="primary" @click="submit">
            บันทึก
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import baseEnum from "@js/enum";
import { mapActions, mapState } from "vuex";
// import autoComplete from "@components/Autocomplete";

export default {
  name: "DialogSettingPermission",
  props: ["modal", "item", "itemIndex"],
  components: {
    // autoComplete,
  },
  data() {
    return {
      // statusData: [
      //   { id: 0, name: "ยกเลิก" },
      //   { id: 1, name: "ใช้งาน" },
      // ],
      // statusField: { value: "id", text: "name" },
      enumStatus: baseEnum.status.data,
      dialog: false,
      itemEdit: {},
      indexEdit: -1,
      // defaultItem: {
      //   PermissionId: "",
      //   PermissionName: "",
      //   Status: 1,
      // },
    };
  },
  mounted() {},
  computed: {
    ...mapState("auth", ["user"]),
    dialogTitle() {
      return this.indexEdit == -1 ? "เพิ่ม" : "แก้ไข";
    },
    isEdit() {
      return this.indexEdit == -1 ? false : true;
    }
  },
  methods: {
    ...mapActions("settings", ["actPermissionUpdate", "actPermissionCreate"]),
    closeModal() {
      // this.item = this.defaultItem;
      // this.indexEdit = -1;
      // this.item = Object.assign({}, this.defaultItem);
      // this.indexEdit = -1;
      this.$emit("close", false);
    },
    cancel() {
      this.closeModal();
    },
    submit() {
      if (this.indexEdit == -1) {
        //เพิ่ม
        this.itemEdit.CreatedBy = this.user.UserId
        this.itemEdit.ModifiedBy = this.user.UserId
        this.actPermissionCreate(this.itemEdit).then(({ status, data }) => {
          if (status == 200 && data.success) {
            this.closeModal();
          } else {
            this.Error(data.message);
          }
        });
      } else {
        //แก้ไข
        this.itemEdit.ModifiedBy = this.user.UserId
        console.log(this.itemEdit);
        this.actPermissionUpdate(this.itemEdit).then(({ status, data }) => {
          if (status == 200 && data.success) {
            this.closeModal();
          } else {
            this.Error(data.message);
          }
        });
      }      
    },
  },
  watch: {
    modal() {
      this.dialog = this.modal;
    },
    item() {
      this.itemEdit = this.item;
    },
    itemIndex() {
      this.indexEdit = this.itemIndex;
    },
  },
};
</script>

<style></style>
